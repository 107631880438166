import { IconButton } from '@chakra-ui/react';
import { useNavigate } from '@remix-run/react';
import { MdArrowBack } from 'react-icons/md';

type BackIconButtonProps = {
  isDetailPage?: boolean;
  onBackButtonClicked?: () => void;
};

const BackIconButton = ({ isDetailPage, onBackButtonClicked }: BackIconButtonProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (onBackButtonClicked) {
      onBackButtonClicked();
      return;
    }
    if (window.history.length <= 2) {
      // 直接URLを叩いて、ページを表示した場合は、違うサイトに飛ばないようにする
      if (isDetailPage) {
        window.location.hash = '/';
      }
      navigate('/');
      return;
    }
    navigate(-1);
  };

  return (
    <IconButton
      onClick={onClick}
      aria-label='Back to list'
      size='md'
      variant='ghost'
      icon={<MdArrowBack />}
    />
  );
};

export default BackIconButton;
